<template>
  <div class="home">
    <el-row :gutter="0">
      <el-col :span="5"> <div class="grid-content bg-purple"></div></el-col>
      <el-col :span="10">
        <el-select v-model="StoreValue" placeholder="Please Select Store">
          <el-option
            v-for="item in StoreOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-divider direction="vertical"></el-divider>

        <el-select v-model="YearValue" placeholder="Please Select Year">
          <el-option
            v-for="item in YearOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-divider direction="vertical"></el-divider>

        <el-select
          v-model="MonthValue"
          placeholder="Please Select Month"
          style="margin-left=10px"
        >
          <el-option
            v-for="item in MonthOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">
          <div class="grid-content bg-purple">
            <el-button align="center" icon="el-icon-search" @click="checkRecord"
              >查询</el-button
            >
          </div>
        </div></el-col
      >
      <el-col :span="6">
        <a>Amount: {{ Amount }}</a>
      </el-col>
    </el-row>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="CreatedAt" label="Time" width="180">
      </el-table-column>
      <el-table-column prop="longitude" label="Longitude" width="180">
      </el-table-column>
      <el-table-column prop="latitude" label="Latitude"> </el-table-column>
    </el-table>
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require("axios");

export default {
  name: "Home",
  components: {},
  data() {
    return {
      Amount: 0,
      tableData: [],
      YearValue: "",
      StoreValue: "",
      MonthValue: "",
      StoreOptions: [
        {
          value: "db9b7665-d769-40d0-acc6-d7ee0940ac58",
          label: "Loffti Cafe",
        },
        {
          value: "f0fb8b0b-e20a-4454-80af-eed4ab2d2343",
          label: "La Postte",
        },
        {
          value: "2129d6bc-92ec-4315-848c-f81124327d7d",
          label: "Top Vegas",
        },
      ],
      YearOptions: [
        {
          value: "2021",
          label: "2021",
        },
        {
          value: "2022",
          label: "2022",
        },
        {
          value: "2023",
          label: "2023",
        },
        {
          value: "2024",
          label: "2024",
        },
      ],
      MonthOptions: [
        {
          value: "1",
          label: "1",
        },
        {
          value: "2",
          label: "2",
        },
        {
          value: "3",
          label: "3",
        },
        {
          value: "4",
          label: "4",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "6",
          label: "6",
        },
        {
          value: "7",
          label: "7",
        },
        {
          value: "8",
          label: "8",
        },
        {
          value: "9",
          label: "9",
        },
        {
          value: "10",
          label: "10",
        },
        {
          value: "11",
          label: "11",
        },
        {
          value: "12",
          label: "12",
        },
      ],
    };
  },
  methods: {
    checkRecord() {
      console.log(
        this.StoreValue + " " + this.YearValue + " " + this.MonthValue
      );

      this.Amount = 0;

      axios
        .get(
          "https://api.dr.search.ren/getRecords?uuid=" +
            this.StoreValue +
            "&year=" +
            this.YearValue +
            "&month=" +
            this.MonthValue
        )
        .then((response) => {
          console.log(response.data.records);
          this.tableData = response.data.records;

          if (
            this.tableData != null &&
            this.tableData != undefined
          ){
            this.Amount = this.tableData.length;
          }else{
            this.Amount = 0;
          }
            
        });
    },
  },
  setup() {
    return {};
  },
};
</script>
